<template>
  <base-section
    id="it-solutions"
    dark
  >
    <base-section-heading
      icon="mdi-monitor"
      title="IT SOLUTIONS"
    />

    <v-container>
      <v-row>
        <v-col
          v-for="card in cards"
          :key="card.title"
          cols="12"
          md="4"
        >
          <base-info-card v-bind="card" />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionItSolutions',

    data: () => ({
      cards: [
        {
          title: 'ERP',
          subtitle: 'Enterprise Resource Planning',
          text: 'Helps you run your entire business, supporting automation and processes in finance, human resources, manufacturing, supply chain, services, procurement, and more',
        },
        {
          title: 'WMS',
          subtitle: 'Warehouse Management System',
          text: 'Receiving management, forwarding management, automation linkage, inventory management, center operation management, carry-out management',
        },
        {
          title: 'WCS',
          subtitle: 'Warehouse Control System',
          text: 'Facility control, facility-linked operation, I/F management',
        },
        {
          title: 'MES',
          subtitle: 'Manufacturing Execution System',
          text: 'Connects, monitors and controls complex manufacturing systems and data flows on the factory floor',
        },
      ],
    }),
  }
</script>
